import LoginCard from "../../../components/auth/login_card";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { Navigate } from "react-router-dom";

export default function LoginPage() {
    const { accessToken } = useSelector((state: RootState) => state.auth);
    if (accessToken) {
        return (
            <Navigate to={"/"} replace={true} />
        )
    }
    return (
        <div>
            {/* <ToastContainer /> */}
            <LoginCard />
        </div>
    )
}
