import { Cookies } from 'react-cookie';

export class CustomCookies {

    public static get Instance() {
        return this.shared || (this.shared = new this());
    }
    static shared: CustomCookies = new CustomCookies();

    cookiesInstance: Cookies;
    constructor() {
        this.cookiesInstance = new Cookies();
    }
}

export const sharedCookies = CustomCookies.Instance.cookiesInstance;
