import { toast } from "react-toastify";

export function defaultErrorToast(message: string) {
    toast(message, {
        hideProgressBar: true,
        theme: 'colored',
        type: 'error',
        autoClose: 3000,
    });
}

export function defaultInfoToast(message: string) {
    toast(message, {
        hideProgressBar: true,
        theme: 'colored',
        type: 'info',
        autoClose: 3000,
    });
}

export function defaultSuccessToast(message: string) {
    toast(message, {
        hideProgressBar: true,
        theme: 'colored',
        type: 'success',
        autoClose: 3000,
    });
}


