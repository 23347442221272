import React from 'react';
import Spinner from './Spinner';

const LoadingScreen = ({ isTransparent = false }: { isTransparent?: boolean }) => {
    if (isTransparent) {
        return (
            <>
            <div className="fixed inset-0 flex items-center justify-center z-50 h-screen bg-gray-500 dark:bg-gray-900 opacity-50">
                <Spinner />
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
            </>
        );
    }
    
    return (
        <>
            <div className="fixed inset-0 flex items-center justify-center z-50 h-screen bg-gray-500 dark:bg-gray-900">
                <Spinner />
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
        </>
    );
};

export default LoadingScreen;
