import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./routing/ProtectedRoute";
import LoginPage from "./pages/auth/login";
import HomePage from "./pages/home";

function Main() {
  return (
    <main className="dark">
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<ProtectedRoute withNavbar={true} />}>
          <Route path="/" element={<HomePage />} />
          {/* <CustomRoute path="/" element={<HomePage />} withNavbar={false} /> */}
        </Route>
        <Route path="*" element={<Navigate to={"/"} replace={true} />} />
      </Routes>
    </main>
  );
}

export default Main;
