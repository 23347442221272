import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ILoginUser, ILoginUserResult } from '../types/auth.types';
import { localStorageKeys } from '../../utils/localStorage/keys';
import customHttpClient from '../../utils/httpClient/httpClient';
import { sharedCookies } from '../../utils/common/cookies';

export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, { getState }) => {
  try {
    await customHttpClient.post('/company/branches/logout');
    return null;
  }
  catch (error) {
    return null;
  }
});

export const loginUser = createAsyncThunk('auth/loginUser', async (loginData: ILoginUser) => {
  const response = await customHttpClient.post<ILoginUserResult>('/company/branches/login', undefined, {
    email: loginData.email,
    password: loginData.password,
    companyAlias: loginData.companyAlias,
    branchName: loginData.branchName,
  });
  return {
    result: response.result,
    rememberMe: !!loginData.rememberMe,
  }
});

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  branchInfo: {
    id: string | null;
    name: string | null;
    allowedCheckinMethods: string[] | null;
  } | null;
  error: string | null;
  logoutError: string | null;
  loading: boolean;
  success: boolean;
}

const initialState: AuthState = {
  accessToken: sharedCookies.get(localStorageKeys.accessToken) || sessionStorage.getItem(localStorageKeys.accessToken) || null,
  refreshToken: sharedCookies.get(localStorageKeys.refreshToken) || sessionStorage.getItem(localStorageKeys.refreshToken) || null,
  branchInfo: sharedCookies.get(localStorageKeys.branchInfo) || JSON.parse(sessionStorage.getItem(localStorageKeys.branchInfo) || null as any),
  error: null,
  logoutError: null,
  loading: false,
  success: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
        // localStorage.removeItem(localStorageKeys.accessToken) // delete token from storage
        // localStorage.removeItem(localStorageKeys.refreshToken) // delete token from storage
        // localStorage.removeItem(localStorageKeys.userInfo) // delete user from storage
        sharedCookies.remove(localStorageKeys.accessToken) // delete token from storage
        sharedCookies.remove(localStorageKeys.refreshToken) // delete token from storage
        sharedCookies.remove(localStorageKeys.branchInfo) // delete user from storage
        
        sessionStorage.removeItem(localStorageKeys.accessToken) // delete token from storage
        sessionStorage.removeItem(localStorageKeys.refreshToken) // delete token from storage
        sessionStorage.removeItem(localStorageKeys.branchInfo) // delete user from storage
        state.loading = false
        state.branchInfo = null
        state.accessToken = null
        state.refreshToken = null
        state.error = null
      },
      setCredentials: (state, { payload }) => {
        state.branchInfo = payload
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        const { result, rememberMe } = action.payload;
        state.accessToken = result.accessToken as string;
        // state.firstName = result.firstName as string;
        // state.lastName = result.lastName as string;
        state.branchInfo = result.branch;
        state.error = null;
        state.success = true; // login successful

        // Store the access token in cookies
        if (rememberMe) {
          // Cookies.set('accessToken', result.accessToken);
          // Cookies.set('refreshToken', result.refreshToken);

          sharedCookies.set(localStorageKeys.accessToken, result.accessToken);
          sharedCookies.set(localStorageKeys.refreshToken, result.refreshToken);
        }
        else {
          sessionStorage.setItem(localStorageKeys.accessToken, result.accessToken);
          sessionStorage.setItem(localStorageKeys.refreshToken, result.refreshToken);
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message as string;
      })
      .addCase(logoutUser.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.logoutError = action.error.message as string;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        sharedCookies.remove(localStorageKeys.accessToken) // delete token from storage
        sharedCookies.remove(localStorageKeys.refreshToken) // delete token from storage
        sharedCookies.remove(localStorageKeys.branchInfo) // delete user from storage
        sharedCookies.remove('connect.sid', { httpOnly: true }) // delete user from storage
        
        sessionStorage.clear();
        state.loading = false
        state.branchInfo = null
        state.accessToken = null
        state.refreshToken = null
        state.error = null
      });
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
