import { useState, ChangeEvent } from 'react';

type IUseInputStateResult<T> = [T, (newValue: T) => void, (event: ChangeEvent<HTMLInputElement>) => void];
function useInputState<T>(initialValue: T): IUseInputStateResult<T> {
  const [value, setValue] = useState<T>(initialValue);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value as T);
  };

  return [value, setValue, handleChange];
};

export default useInputState;

