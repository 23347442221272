"use client";
import { AppDispatch, RootState } from "../../stores";
import { loginUser } from "../../stores/reducers/authSlice";
import { ILoginUser } from "../../stores/types/auth.types";
import { FormEvent, useEffect, useRef } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useInputState from "../../hooks/useInputState";
import LoadingScreen from "../common/loading/LoadingScreen";
import { defaultErrorToast } from "../common/toasts";
import * as yup from 'yup';

export default function LoginCard() {
  const loginButtonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [branchName, _setBranchName, onBranchNameChange] = useInputState('');
  const [companyAlias, _setCompanyAlias, onCompanyAliasChange] = useInputState('');
  const [email, _setEmail, onEmailChange] = useInputState('');
  const [password, _setPassword, onPasswordChange] = useInputState('');
  const [rememberMe, _setRememberMe, onRememberMeChange] = useInputState(false);
  const { accessToken, loading, error } = useSelector(
    (state: RootState) => state.auth
  );

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    loginButtonRef.current?.blur();
    if (loading) {
      return;
    }
    
    const schema = yup.object({
      branchName: yup.string().min(1, 'Branch name is required').required(),
      companyAlias: yup.string().trim().min(1, 'Company alias isrequired').required(),
      email: yup.string().trim().email('Email must be valid').required(),
      password: yup.string().min(6, 'Password must be atleast 6 characters').required(),
    }).unknown(true);
    

    try {
      const validatedObject = schema.validateSync({
        email,
        password,
        branchName,
        companyAlias,
      })
      
      const userData: ILoginUser = {
          ...validatedObject,
          rememberMe,
      } as ILoginUser;
      dispatch(loginUser(userData));
    }
    catch (error: any) {
      defaultErrorToast(error.message);
    }
  }

  useEffect(() => {
    if (error) {
      defaultErrorToast(error);
    }
  }, [error]);

  if (!!accessToken) {
    return (
      <Navigate to={"/"} replace={true} />
    )
  }
  return (
    <div>
      {loading && <LoadingScreen isTransparent={true} />}
      {(!loading || loading) && (
        <div>
          <section className="bg-gray-50 dark:bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto min-h-screen lg:py-0">
              <Link
                to="/"
                className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
              >
                <img
                  className="w-14 h-14 mr-6 rounded-full"
                  src="herpyIconBlueBgx1024.png"
                  alt="logo"
                  width={200}
                  height={200}
                />
                Herpy Branch QR
              </Link>
              <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Sign in to your account
                  </h1>
                  <form
                    onSubmit={handleSubmit}
                    className="space-y-4 md:space-y-6"
                    action=""
                  >
                    <div>
                      <label
                        htmlFor="companyAlias"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Company Alias
                      </label>
                      <input
                        autoFocus={true}
                        type="text"
                        name="branchName"
                        id="companyAlias"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="xyz"
                        required={true}
                        value={companyAlias}
                        onChange={onCompanyAliasChange}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="branchName"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Branch Name
                      </label>
                      <input
                        type="text"
                        name="branchName"
                        id="branchName"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Branch A"
                        required={true}
                        value={branchName}
                        onChange={onBranchNameChange}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="name@example.com"
                        required={true}
                        value={email}
                        onChange={onEmailChange}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        id="password"
                        placeholder="••••••••"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required={true}
                        value={password}
                        onChange={onPasswordChange}
                      />
                    </div>
                    <div className="flex items-center justify-between">
                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="remember"
                            aria-describedby="remember"
                            type="checkbox"
                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                            required={false}
                            onChange={onRememberMeChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="remember"
                            className="text-gray-500 dark:text-gray-300"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                    </div>
                    <button
                      ref={loginButtonRef}
                      type="submit"
                      className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    >
                      Sign in
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
}
