import { logoutUser } from '../../stores/reducers/authSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../stores';
import QRCode from 'react-qr-code';
import { useEffect, useState } from 'react';
import { IBranchInfo } from '../../stores/types/auth.types';
import { EventSourcePolyfill } from 'event-source-polyfill';
import customHttpClient, { API_PREFIX } from '../../utils/httpClient/httpClient';
import moment from 'moment';

export default function HomePage() {
    let [qrCode, setQrCode] = useState(null as string | null);
    let [originalCode, setOriginalCode] = useState(null as string | null);
    let [branchInfo, setBranchInfo] = useState(null as IBranchInfo | null);
    
    const dispatch = useDispatch<AppDispatch>();
    function handleTestOnClick() {
        dispatch(logoutUser());
    }

    async function getBranchInfo() {
        const branchInfo = await customHttpClient.get<IBranchInfo>('/company/branches/me');
        setBranchInfo(branchInfo.result);
    }
    
    useEffect(() => {
        getBranchInfo().catch(error => {alert(error.message)});
        const es = new EventSourcePolyfill(`${API_PREFIX}/company/branches/qr-code`, {
            headers: customHttpClient.getCommonHeaders(),
            heartbeatTimeout: 3600 * 1000,
        });
        const intervalId = setInterval(() => {
            setQrCode((qrCode) => {
                const originalCode = qrCode?.split('-').slice(0, -1).join('-');
                return `${originalCode}-${moment.utc().toDate().getTime()}`;
            })
        }, 10 * 1000);
        es.onmessage = (message) => {
            try {
                const parsed = JSON.parse(message.data);
                setOriginalCode(parsed.code);
                setQrCode(`${parsed.code}-${moment.utc().toDate().getTime()}`);
            }
            catch (error) {

            }
        }
        es.onerror = (message) => {
        }
        es.onopen = (message) => {
        }

        return (() => {
            es.close();
            clearInterval(intervalId);
        });
    }, []);

    return (
        <div className='dark:bg-gray-100 bg-gray-100 pb-10 h-screen'>
            <div className='flex absolute mt-4 ml-4'>
                <img
                  className="w-14 h-14 mr-6 rounded-full"
                  src="herpyIconBlueBgx1024.png"
                  alt="logo"
                  width={200}
                  height={200}
                />
            </div>
            <div className='flex float-right'>
                <button className='primary dark:bg-blue-400 px-5 py-2 mt-5 mr-8 rounded-lg bg-gray-100 text-xl' onClick={handleTestOnClick}>Logout</button>
            </div>
            <div className='flex items-center min-h-screen'>
                <div className='m-auto align-middle space-y-8 pb-32'>
                    <div className='text-2xl font-semibold text-center dark:text-black'>
                        Branch: {branchInfo?.name}
                    </div>
                    <div className='px-10'>
                    {/* { !!qrCode && <QRCode className='mx-auto' value={qrCode} fgColor='#0000FF10' bgColor='#555' size={85} /> } */}
                    { !!qrCode && <QRCode className='mx-auto' bgColor='rgb(243, 244, 246)' fgColor='#000' value={qrCode} level='M'  /> }
                    </div>
                </div>
            </div>
        </div>
    );
}
