import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import '@smastrom/react-rating/style.css'
import 'react-pagination-bar/dist/index.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './stores/index';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode> {/* Consider React.Fragment in PROD */}
    <Provider store={store}>
        <App />
      {/* <GoogleOAuthProvider clientId={'768183566081-9qs2mc71i9bnf7i4pd891v4ooakdsgqb.apps.googleusercontent.com' as string}>
      </GoogleOAuthProvider> */}
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
