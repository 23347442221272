import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from "axios";
import { localStorageKeys } from "../localStorage/keys";
import { sharedCookies } from "../common/cookies";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { Dispatch } from "react";
import { logoutUser } from "../../stores/reducers/authSlice";
import moment from "moment";

export const API_PREFIX = `${process.env.REACT_APP_BACKEND_API_URL}/api/v1`

export type FormattedResponse<ExpectedResponseDataType> = {
    result: ExpectedResponseDataType,
    pagination?: {
      pageSize: number,
      numPages: number,
      afterId: string,
    },
    statusCode: number,
    statusMessage: string,
    success: boolean,
}

export interface IErrorResponse {
  message: string;
  responseCode: string;
  code: number;
}

export type IDispatchCallback = ThunkDispatch<{
  auth: any;
}, undefined, AnyAction> & Dispatch<AnyAction>;

class CustomHttpClient {
  private axiosInstance: AxiosInstance;

  static shared: CustomHttpClient = new CustomHttpClient();

  public static get Instance() {
    return this.shared || (this.shared = new this());
  }

  constructor() {
    this.axiosInstance = axios.create();

    // Add request interceptor
    this.axiosInstance.interceptors.request.use(
      (config) => this.requestInterceptor(config),
      (error) => Promise.reject(error)
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return Promise.resolve(response?.data);
      },
      (error) => {
        const errorData = error?.response?.data;
        return Promise.reject(errorData);
      }
    );
  }

  private requestInterceptor(
    config: AxiosRequestConfig
  ): InternalAxiosRequestConfig {
    const accessToken = sharedCookies?.get(localStorageKeys.accessToken) || sessionStorage?.getItem(localStorageKeys.accessToken);
    if (accessToken && config.headers && !config.headers["Authorization"]) {
      // Add the access token to the request headers
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    if (config.headers) {
      config.headers['platform'] = 'web';
      config.headers['app-version'] = '1.0.0';
      config.headers['version'] = '1.0.0';
      config.headers['language'] = 'en';
      config.headers['gmtOffset'] = '+02:00';
    }
    return config as InternalAxiosRequestConfig;
  }

  public getCommonHeaders() {
    let result: any = {};
    const accessToken = sharedCookies?.get(localStorageKeys.accessToken) || sessionStorage?.getItem(localStorageKeys.accessToken);
    if (accessToken) {
      // Add the access token to the request headers
      result["Authorization"] = `Bearer ${accessToken}`;
    }
    result['platform'] = 'web';
    result['app-version'] = '1.0.0';
    result['version'] = '1.0.0';
    result['language'] = 'en';
    result['gmtOffset'] = '+02:00';

    return result;
  }

  public async get<ExpectedResponseDataType>(relativePath: string, dispatch?: IDispatchCallback, config?: AxiosRequestConfig): Promise<FormattedResponse<ExpectedResponseDataType>> {
    try {
      return await this.axiosInstance.get(`${API_PREFIX}${relativePath}`, config);
    }
    catch (error: any) {
      if (error?.code === 401) {
        // dispatchLogout();
        if (!!dispatch) {
          dispatch(logoutUser());
        }
      }
      else if (error) {
        throw error;
      }
      throw new Error("Unknown error occured. Please try again later");
    }
  }

  public async post<ExpectedResponseDataType>(relativePath: string, dispatch?: IDispatchCallback, data?: any, config?: AxiosRequestConfig): Promise<FormattedResponse<ExpectedResponseDataType>> {
    try {
      return await this.axiosInstance.post(`${API_PREFIX}${relativePath}`, data, config);
    }
    catch (error: any) {
      if (error?.code === 401) {
        // dispatchLogout();
        if (!!dispatch) {
          dispatch(logoutUser());
        }
      }
      else if (error) {
        throw error;
      }
      throw new Error("Unknown error occured. Please try again later");
    }
  }

  public async put<ExpectedResponseDataType>(relativePath: string, dispatch?: IDispatchCallback, data?: any, config?: AxiosRequestConfig): Promise<FormattedResponse<ExpectedResponseDataType>> {
    try {
      return await this.axiosInstance.put(`${API_PREFIX}${relativePath}`, data, config);
    }
    catch (error: any) {
      if (error?.code === 401) {
        // dispatchLogout();
        if (!!dispatch) {
          dispatch(logoutUser());
        }
      }
      else if (error) {
        throw error;
      }
      throw new Error("Unknown error occured. Please try again later");
    }
  }

  public async patch<ExpectedResponseDataType>(relativePath: string, dispatch?: IDispatchCallback, data?: any, config?: AxiosRequestConfig): Promise<FormattedResponse<ExpectedResponseDataType>> {
    try {
      return this.axiosInstance.patch(`${API_PREFIX}${relativePath}`, data, config);
    }
    catch (error: any) {
      if (error?.code === 401) {
        // dispatchLogout();
        if (!!dispatch) {
          dispatch(logoutUser());
        }
      }
      else if (error) {
        throw error;
      }
      throw new Error("Unknown error occured. Please try again later");
    }
  }

  public async delete<ExpectedResponseDataType>(relativePath: string, dispatch?: IDispatchCallback, config?: AxiosRequestConfig): Promise<FormattedResponse<ExpectedResponseDataType>> {
    try {
      return await this.axiosInstance.delete(`${API_PREFIX}${relativePath}`, config);
    }
    catch (error: any) {
      if (error?.code === 401) {
        // dispatchLogout();
        if (!!dispatch) {
          dispatch(logoutUser());
        }
      }
      else if (error) {
        throw error;
      }
      throw new Error("Unknown error occured. Please try again later");
    }
  }
}

const customHttpClient = CustomHttpClient.Instance;
export default customHttpClient;
